<template>
    <v-row justify="center">
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
      >
        <v-card>
            <dialog-yes-no-component ref="dialogYesNoRef" :width="700" :height="600" :showTable="true"></dialog-yes-no-component>
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{ proyecto + " (" + nombre + ")" }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="guardar"
                    >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
                <v-btn
                    class="ma-2"
                    dark
                    color="green"
                    large
                    @click="finalizar"
                    >
                    <v-icon left>
                        mdi-checkbox-marked-circle-outline
                    </v-icon>
                    Finalizar
                </v-btn>
            </v-card-actions>
            <template v-if="visita">
                <div class="tabla-container">
                    <table-component class="mt-4" style="margin-top: -60px !important;" ref="tablaVisitas" :headers="visita.auditoria ? headers_auditoria : headers_inspeccion" :url="'/compromisos/visita/'+visita.id" :padreId="visita_id" view="ejecuta-visita" labelSearch="Búsqueda de Unidades Fiscalizables" :tableHeight="altoPorResolucion" :key="keyTabla" @tabla-cargada="asignarValor" :rowsPerPage=-1 :showAdd="false" :disbleItemsPerPage="true" :disablePagination="true"></table-component>
                </div>
            </template>
        </v-card>
        <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
        <load-data-component ref="load"></load-data-component>
      </v-dialog>
    </v-row>
  </template>

<script>
    import axios from "axios";
    import { EventBus } from "@/event/event"

    export default {
        data () {
            return {
                dialog: false,
                notifications: false,
                sound: true,
                widgets: false,
                visita: [],
                headers_auditoria: [
                    { text: '#', value: 'codigo', width: '60px', filtro:false },
                    { text: 'Nombre', value: 'nombre', width: '200px', filtro:false },
                    { text: 'Descripción', value: 'descripcion', width: '500px', filtro:false },
                    { text: 'Componente', value: 'componente', width: '200px', filtro:false },
                    { text: 'Verificador', value: 'verificador', width: '200px', filtro:false },
                    { text: 'Descripción del Verificador', value: 'descripcion_verificador', width: '300px', filtro:false },

                    { text: 'Autoridad', value: 'autoridad', width: '180px', filtro:false },
                    { text: 'Fase', value: 'fase.nombre', width: '180px', filtro:false },
                    /* { text: 'Frecuencia', value: 'frecuencia.nombre', width: '180px', filtro:false }, */
                    { text: 'Frecuencia', value: 'frecuencia_sugerida.nombre', width: '180px', filtro:false },
                    { text: 'Instrumento', value: 'instrumento.nombre', width: '180px', filtro:false },
                    { text: 'Norma', value: 'norma', width: '180px', filtro:false },
                    { text: 'Origen', value: 'origen', width: '180px', filtro:false },
                    { text: 'Vencimiento', value: 'vencimiento', width: '180px', filtro:false },

                    { text: 'Estado', value: 'estado-editable', width: '200px', filtro:false },
                    { text: 'Hallazgo', value: 'observacion-editable', width: '500px', filtro:false },
                    { text: 'Plan de acción', value: 'plan_accion-editable', width: '400px', filtro:false },
                    { text: 'Cargar documentos', value: 'actions', width: '180px', filtro:false, align: 'center' },
                ],
                headers_inspeccion: [
                    { text: '#', value: 'codigo', width: '60px', filtro:false },
                    { text: 'Nombre', value: 'nombre', width: '200px', filtro:false },
                    { text: 'Descripción', value: 'descripcion', width: '500px', filtro:false },
                    { text: 'Componente', value: 'componente', width: '200px', filtro:false },
                    { text: 'Verificador', value: 'verificador', width: '200px', filtro:false },
                    { text: 'Descripción del Verificador', value: 'descripcion_verificador', width: '300px', filtro:false },

                    { text: 'Autoridad', value: 'autoridad', width: '180px', filtro:false },
                    { text: 'Fase', value: 'fase.nombre', width: '180px', filtro:false },
                    /* { text: 'Frecuencia', value: 'frecuencia.nombre', width: '180px', filtro:false }, */
                    { text: 'Frecuencia', value: 'frecuencia_sugerida.nombre', width: '180px', filtro:false },
                    { text: 'Instrumento', value: 'instrumento.nombre', width: '180px', filtro:false },
                    { text: 'Norma', value: 'norma', width: '180px', filtro:false },
                    { text: 'Origen', value: 'origen', width: '180px', filtro:false },
                    { text: 'Vencimiento', value: 'vencimiento', width: '180px', filtro:false },

                    { text: 'Observación', value: 'observacion-editable', width: '500px', filtro:false },
                    { text: 'Cargar documentos', value: 'actions', width: '180px', filtro:false, align: 'center' },
                ],
                item: [],
                nombre: '',
                proyecto: '',
                disabled: false,
                valid: true,
                keyTabla: 0,
                contador: 0,
                snackbar: false,
                texto: '',
                estado: 0,
                visita_id: 0,
                overlay: false,
                altoPorResolucion: 520,
            }
        },
        methods: {
            async open(item) {
                this.overlay = true
                if (item) {
                    await axios.get('visitas/' + item.id ).then((response) => {
                        item = response.data.data
                        this.visita = item
                        this.item = item.compromisos
                        this.nombre = item.nombre
                        this.proyecto = item.proyecto.nombre
                        this.dialog = true
                        this.visita_id = item.id
                    }).catch((error) => {
                        console.error(error);
                    });
                }
                this.overlay = false
            },
            asignarValor() {
                this.$refs.tablaVisitas.setearValoresEjecucion(this.visita)
            },
            async guardar() {
                const valorTabla = this.$refs.tablaVisitas.obtenerValoresEjecucion()
                if (valorTabla.length > 0) {
                    const data = {
                        datos: valorTabla,
                        tipo: 'revision_borrador'
                    }
                    await axios.put('/visitas/ejecutar/' + this.visita.id, data)
                    .then((response) => {
                        this.snackbar = true;
                        this.texto = response.data.message
                        this.estado = response.data.code
                    }).catch((error) =>{
                        console.error(error)
                    })
                }
            },
            close() {
                this.visita = null
                EventBus.$emit('event-close')
                this.dialog = false
            },
            async finalizar() {
                //this.$refs.load.open();
                const valorTabla = this.$refs.tablaVisitas.obtenerValoresEjecucion()
                const data = {
                    datos: valorTabla,
                    tipo: 'revision_final'
                }
                await axios.put('/visitas/ejecutar/' + this.visita.id, data)
                .then((response) => {
                    const params = {
                        visita_id: this.visita.id,
                        nombre: "Acta de terreno " + this.nombre,
                        tipo: 'revision_final',
                        auditoria: this.visita.auditoria
                    }
                    this.$refs.dialogYesNoRef.open('Finalizar', 'Al finalizar, se cerrará la visita y se generará Acta de terreno', 'generar_acta', params);
                    //this.$refs.load.close();
                    
                    /*this.snackbar = true;
                    this.texto = response.data.message
                    this.estado = response.data.code*/
                }).catch((error) =>{
                    console.error(error)
                })
            },
            handleResize() {
                let screenWidth = window.innerWidth;
                let screenHeight = window.innerHeight;
                if (screenWidth <= 1366 && screenHeight <= 768) {
                    this.altoPorResolucion = 350
                } else if (screenWidth > 1366 && screenHeight > 768) {
                    this.altoPorResolucion = 600
                }
            }
        },
        mounted() {
            EventBus.$on('close-ejecutar-visita', () => {
                this.dialog = false
            });
            window.addEventListener('resize', this.handleResize);
            this.handleResize()
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        }
    }
</script>

<style scoped>
     ::v-deep  .v-dialog--fullscreen {
        top: 80px;
        left: 25px;
        max-width: 100% !important;
        max-height: 100% !important;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    v-card {
        position: fixed !important;
    }
    /* @media (max-width: 1366px) and (max-height: 768px) {
        .tabla-container {
            max-height: 400px; 
            overflow-y: scroll;
            overflow-x: hidden;
        }
    } */
</style>