<template>
  <div class="text-center">
    <v-overlay :value="overlay" :key="contador" style="z-index: 9999;">
      <v-card
    >
      <v-container style="height: 150px;">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
          style="font-weight: bold;"
        >
          Cargando, por favor espere...
        </v-col>
        <v-col cols="6">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
    </v-overlay>
  </div>
</template>

<script>
  import axios from "axios";
  import { commonStore } from "@/store/CommonStore";
  const networkTracker = {
    activeRequests: 0,
    lastRequestId: null,
    callbacks: [],
    onStartCallbacks: [], // Callbacks para nuevas solicitudes
    onCompleteCallbacks: [], // Callbacks para solicitudes terminadas

    onStart(callback) {
      this.onStartCallbacks.push(callback);
    },
    onComplete(callback) {
      this.onCompleteCallbacks.push(callback);
    },
    requestStarted(requestId) {
      this.activeRequests += 1;
      // Notificar a los callbacks de inicio
      this.onStartCallbacks.forEach((cb) => cb(requestId));
      this.lastRequestId = requestId;
    },
    requestFinished(requestId) {
      this.activeRequests -= 1;
      if (this.activeRequests < 0) {
        console.warn("Contador de solicitudes es negativo. Esto no debería ocurrir.");
        this.activeRequests = 0;
      }
      if (this.activeRequests === 0) {
        // Notificar a los callbacks de finalización
        this.onCompleteCallbacks.forEach((cb) => cb(requestId));
      }
    }
  };

  // Interceptores de Axios
  axios.interceptors.request.use((config) => {
    const requestId = Date.now();
    config.headers["X-Request-ID"] = requestId;
    networkTracker.requestStarted(requestId);
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      const requestId = response.config.headers["X-Request-ID"];
      networkTracker.requestFinished(requestId);
      return response;
    },
    (error) => {
      const requestId = error.config?.headers["X-Request-ID"];
      networkTracker.requestFinished(requestId);
      return Promise.reject(error);
    }
  );
export default {
  data: () => ({
    overlay: false,
    contador: 0,
    commonStore: commonStore()
  }),

  methods: {
    open() {
        this.overlay = true
    },
    close() {
        this.overlay = false
    }
  },
  mounted() {
    // Llamar a `open` cuando inicie una nueva solicitud
    networkTracker.onStart(() => {
        if (this.commonStore.overlay == false) {
          this.open();
          this.commonStore.showOverlay()
        }
    });
    // Usar el tracker para ejecutar algo al final
    networkTracker.onComplete((lastRequestId) => {
      //console.log("¡Todas las solicitudes han terminado! Última solicitud:", lastRequestId);
      if (this.commonStore.overlay == true) {
        this.close()
        this.commonStore.hideOverlay()
      }
    });
  }
}
</script>

<style scoped>
.theme--dark.v-card {
  background: #282c2dad;
  box-shadow: none;
  border-radius: 6px;
  width: auto;
  color: whitesmoke;
}
</style>