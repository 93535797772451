<template>
  <v-app>
    <LoadData ref="load"></LoadData>
    <header class="primary-header">
      <template>
        <v-dialog v-model="dialog" max-width="450" height="50vh" persistent>
          <v-card>
            <v-card-title class="justify-center">La sesión está a punto de expirar</v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col>
                  <div class="text-center">
                    <span>¿Desea mantener la sesión iniciada?</span>
                    <br>
                    <span>De lo contrario, será redirigido para volver a iniciar sesión</span>
                    <br>
                    <br>
                  </div>
                  <flip-countdown 
                    :deadline="deadline" 
                    :showDays="false"
                    :showHours="false"
                  ></flip-countdown>
                  <div class="text-center mt-6">
                    <v-btn class="ml-3" color="primary" @click="acceptDialog()">Aceptar</v-btn>
                    <v-btn class="ml-3" color="error" @click="cancelDialog()">Cerrar sesión</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <nav class="navbar container">
        <v-btn style="margin-left: -30px;" large icon @click="toggleMenu" v-if="this.$route.name !== 'login' && !this.$route.query.alerta"><!-- v-if="!$vuetify.breakpoint.mdAndUp" -->
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <img class="mt-1" src="../assets/logo.png" height="100"/>
        <v-row style="justify-content: end" v-if="this.$route.name !== 'login' && !this.$route.query.alerta">
          <div>
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="100" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="white" v-bind="attrs" v-on="on">
                  {{ nombreUsuario }}
                  <v-icon right>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-list>
                  <v-list-item>
                    <!--<v-list-item-avatar>
                      <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
                    </v-list-item-avatar>-->
                    <v-list-item-content>
                      <v-list-item-title>{{ nombreUsuario }}</v-list-item-title>
                      <v-list-item-subtitle>{{ perfil }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item link @click="cambiarContrasena">
                    <v-icon left>
                      mdi-lock-reset
                    </v-icon>
                      Cambiar contraseña
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item link @click="preferenciasUsuario">
                    <v-icon left>
                      mdi-account-edit
                    </v-icon>
                      Preferencias de usuario
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item link>
                    <v-icon left color="red">
                      mdi-power
                    </v-icon>
                    <a class="a-href" @click="logout">
                      Cerrar sesión
                    </a>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </nav>
    </header>
    
    <v-navigation-drawer
      v-model="drawer"
      absolute
      app 
      bottom
      temporary
      class="mt-14"
      :class="{ 'fixed-drawer': fixed }"
    >
      <v-list nav dense>
        <v-list-item link to="home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>
        <v-list-item link to="nuevo-proyecto">
          <v-list-item-icon>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Unidad Fiscalizable</v-list-item-title>
        </v-list-item>
        <v-list-item link to="nueva-visita" v-if="perfil_id != perfilStore.titular">
          <v-list-item-icon>
            <v-icon>mdi-camera</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Visitas</v-list-item-title>
        </v-list-item>
        <v-list-item link to="calendario">
          <v-list-item-icon>
            <v-icon>mdi-calendar-month</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Calendario</v-list-item-title>
        </v-list-item>
        <v-list-group v-model="activeMantenedores" prepend-icon="mdi-cog" v-if="perfil_id == perfilStore.administrador || perfil_id == perfilStore.jefatura">
          <template v-slot:activator>
            <v-list-item-title>Mantenedores</v-list-item-title>
          </template>
          <v-list-item v-for="item in itemsMantenedor" :key="item.view" link @click="send(item)">
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
          <v-list-item @click="asignacion('uf')">
            <v-list-item-title v-text="'Asignación UF'"></v-list-item-title>
          </v-list-item>
          <v-list-item @click="asignacion('formulario')">
            <v-list-item-title v-text="'Asignación Formularios'"></v-list-item-title>
          </v-list-item>
          <v-list-item @click="asignacion('titular')">
            <v-list-item-title v-text="'Asignación Titulares'"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    
    <div class="content">
      <router-view/>
    </div>
    <PreferenciasUsuarioForm ref="preferenciasUsuarioRef" @close-sesion="notificacion" title="Preferencias del usuario"></PreferenciasUsuarioForm>
    <SnackBar :snackbar.sync="snackbar" :texto="texto" :estado="estado"></SnackBar>
  </v-app>
</template>

<script>
import axios from "axios";
import styles from "../styles.css";
import { perfilStore } from "@/store/PerfilStore"
import { mantenedorStore } from "@/store/MantenedorStore"
import PreferenciasUsuarioForm from "@/views/formulario/PreferenciasUsuarioForm.vue";
import SnackBar from "./SnackBar.vue";
import FlipCountdown from 'vue2-flip-countdown'
import LoadData from '@/components/LoadData.vue'

export default {
  components: {
    SnackBar,
    PreferenciasUsuarioForm,
    FlipCountdown,
    LoadData
  },
  data: () => ({
    show: true,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    drawer: false,
    group: null,
    nombreUsuario: null,
    perfil: null,
    perfil_id: null,
    perfilStore: perfilStore(),
    mantenedorStore: mantenedorStore(),
    itemsMantenedor: [],
    contador: 0,
    login: null,
    intervalSesion: null,
    timeOut2: null,
    dialog: false,
    timeAlert: 300000,
    snackbar: false,
    texto: '',
    estado: 0,
    activeMantenedores: false,
    fixed: false,
    deadline: "2018-12-25 00:00:00",
  }),
  watch: {
    '$route'(to, from) {
      if (to.name != 'login') {
        this.nombreUsuario = localStorage.getItem('nombre')
        this.perfil = localStorage.getItem('perfil')
        this.perfil_id = this.perfilStore.decryptData(localStorage.getItem('perfil_id'))
        this.validarToken()
        this.alertaSesion()
      }
    },
  },
  methods: {
    alertaSesion() {
      if (this.intervalSesion) {
        clearInterval(this.intervalSesion);
      }
      this.intervalSesion = setInterval(() => {
        const horaActual = new Date().getTime();
        const exp_token = localStorage.getItem('exp_token');
        const hora_aviso = exp_token-this.timeAlert
        if (horaActual >= hora_aviso) {
          this.deadline = this.getDeadline(300)
          this.dialog = true;
          this.pauseTimer();
          this.timeOut2 = setTimeout(() => {
            this.cancelDialog()
          }, 300000);
        }
      }, 1000);
    },
    pauseTimer() {
      clearInterval(this.intervalSesion); // Detener el intervalo
    },
    cancelDialog() {
      this.dialog = false
      clearTimeout(this.timeOut2);
      clearInterval(this.intervalSesion);
      setTimeout(() => {
        this.logout()
      },100);
      
    },
    acceptDialog() {
      axios.get('renewToken', 
      {
        params: {
          id: this.perfilStore.decryptData(localStorage.getItem('id'))
        },
      }).then(response => {
        const newToken = response.data.token.token;
        const newTokenExp = new Date(response.data.exp_token).getTime();
        localStorage.setItem('token', newToken)
        localStorage.setItem('exp_token', newTokenExp)
        clearTimeout(this.timeOut2);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.validarToken()
        this.dialog = false;
        this.alertaSesion()
      });
    },
    getDeadline(seconds) {
      const date = new Date(Date.now() + seconds * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const secondsStr = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${secondsStr}`;
    },
    logout() {
      axios
      .post("/logout")
      .then((response) => {
        this.pauseTimer()
        this.$router.push({ name: "login" });
        localStorage.removeItem('perfil_id')
        localStorage.removeItem('id')
        localStorage.removeItem('nombre')
        localStorage.removeItem('perfil')
        localStorage.removeItem('token')
        localStorage.removeItem('login')
        localStorage.removeItem('exp_token')
        window.location.href = '/';
      }).catch((error) => {
        console.error(error);
        this.$router.push({ name: "login" });
      })
    },
    toggleMenu() {
      this.activeMantenedores = false
      this.drawer = !this.drawer;
      this.fixed = this.drawer;
    },
    send(item) {
      const timestamp = new Date().getTime();
      localStorage.setItem('mantenedor', item.id);
      this.$router.push({name: 'mantenedores', query: {refresh: timestamp}})
    },
    validarToken() {
      const exp_token = localStorage.getItem('exp_token');
      const hora = new Date().getTime();
      if (hora >= exp_token) {
        this.cancelDialog()
      }
    },
    cambiarContrasena() {
      this.$router.push({ name: "cambio-contrasena" }).catch(() => {})
    },
    notificacion(response) {
      this.snackbar = true;
      this.texto = response.data.message
      this.estado = response.data.code
    },
    preferenciasUsuario() {
      this.$refs.preferenciasUsuarioRef.open()
    },
    asignacion(view) {
      const currentRoute = this.$route.path;
      let targetRoute
      switch (view) {
        case 'uf':
          targetRoute = '/asignacion'
          if (currentRoute !== targetRoute) {
            this.$router.push(targetRoute);
          }
          break;
        case 'formulario':
          targetRoute = '/asignacion-formulario'
          if (currentRoute !== targetRoute) {
            this.$router.push(targetRoute);
          }
          break;
        case 'titular':
          targetRoute = '/asignacion-titular'
          if (currentRoute !== targetRoute) {
            this.$router.push(targetRoute);
          }
          break;
      }
      
    }
  },
  mounted() {
    this.itemsMantenedor = this.mantenedorStore.itemsMantenedor
    if (this.$route.path != '/') {
      this.alertaSesion()
      this.nombreUsuario = localStorage.getItem('nombre')
      this.perfil = localStorage.getItem('perfil')
      this.perfil_id = this.perfilStore.decryptData(localStorage.getItem('perfil_id'))
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.primary-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: background-color 0.5s;
}
.navbar {
  height: 3.5rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
  transition: height 0.25s;
  background: #08697e;
}
.navbar2 {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
  transition: height 0.25s;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 10px -7px #000;
}
.logo {
  font-size: 1.4rem;
  font-weight: 900;
}
.nav-list {
  list-style: none;
  display: flex;
  gap: 2.5rem;
  margin-top: 0.2rem;
  margin-left: 2rem;
  margin-right: auto;
}
.nav-link {
  transition: color 0.25s;
  color: #151515;
}
.nav-link:hover {
  color: #d3d3d3;
}
.button {
  display: inline-block;
  background-color: #fff;
  color: #313131;
  padding: 0.8rem 2rem;
  border-radius: 2rem;
  transition: background-color 0.25s;
}
.button:hover {
  background-color: #d3d3d3;
}
header.active {
  background-color: #fff;
  box-shadow: 0 3px 1rem rgba(0, 0, 0, 0.1);
}
header.active .navbar {
  height: 6rem;
}
header.active .logo,
header.active .nav-link {
  color: #313131;
}
header.active .nav-link:hover {
  color: #808080;
}
header.active .button {
  background-color: #313131;
  color: #fff;
}
header.active .button:hover {
  background-color: #151515;
}
.theme--light.v-list {
  background: #eaebeb;
  color: rgba(0, 0, 0, 0.87);
}
.v-list-item:hover {
  background-color: #d3d3d3;
}
.a-href {
  color: rgb(253, 0, 0) !important;
  caret-color: #ff0000 !important;
}
  .transparent {
    background: transparent;
  }

  .content {
    margin-top: 3%; /* Elimina el margen */
    padding: auto;
    max-width: 100%;
  }
  @media (max-width: 600px) {
    /* Estilos específicos para pantallas móviles */
    .content {
      margin-top: 15%; /* Aumenta el margen en pantalla móvil */
    }
  }

  .fixed-drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
  }
  ::v-deep .flip-clock__slot {
    display: none !important
  }
  ::v-deep .flip-card__top,
  ::v-deep .flip-card__bottom,
  ::v-deep .flip-card__back-bottom,
  ::v-deep .flip-card__back::before,
  ::v-deep .flip-card__back::after {
    color: #ccc;
    background: #08697e;
  
  }

  ::v-deep .flip-card__bottom,
  ::v-deep .flip-card__back-bottom {
  color: #FFF;
  border-top: solid 1px #000;
  background: #08697e;
}

::v-deep.flip-card__back-bottom {
  z-index: 1;
}

</style>
