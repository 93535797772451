<template>
    <v-row justify="end" class="mr-1">
        <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="text-center">
        <v-dialog v-model="dialog" persistent>
            <v-card>
                <dialog-yes-no-component ref="dialogYesNoRef" :width="400"></dialog-yes-no-component>
                <v-toolbar flat class="dialog-toolbar">
                    <v-toolbar-title class="text-h5">Visita</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cancelar">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text style="padding-bottom: 0px;">
                    <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <select-component label="Seleccione Unidad Fiscalizable" ref="sProyecto" url="/proyectos/visitas" @selected-value="handleSelectedValue" :valor="proyecto" :disabled="disabled"></select-component>
                                </v-col>
                                <v-col cols="3">
                                    <check-box-component 
                                        label="¿Es auditoria?"
                                        ref="chAuditoria"
                                        :valor="auditoria"
                                    ></check-box-component>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    <text-field-component label="Nombre *" ref="tfNombre" :valor="nombre" required :disabled="disabled"></text-field-component>
                                </v-col>
                                <v-col cols="2">
                                    <date-picker-component label="Fecha" ref="dpFecha" :valor="fecha"></date-picker-component>
                                </v-col>
                                <v-col cols="1" style="padding-top: 0px;">
                                    <time-picker-component label="Hora" ref="tfHora" :valor="hora_inicio"></time-picker-component>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="5">
                                    <text-field-component class="mt-1" label="Tema" ref="tfTema" :valor="tema" :disabled="disabled" :counter="130"></text-field-component>
                                </v-col>
                                <v-col cols="6" v-if="contactosSelect && titular">
                                    <select-component label="Contactos" ref="sContacto" :url="'/contactos/titular/' + titular.id" :multiple="true" :key="keyContacto" itemValue="contacto_id" :valorMultiple="contactos" :disabled="disabled"></select-component>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-row style="text-align: end;">
                        <v-col cols="12">
                            <v-btn
                                @click="cancelar"
                                class="ma-2"
                                large
                            >
                                Cerrar
                            </v-btn>
                            <v-btn
                                v-if="!disabled"
                                class="ma-2"
                                dark
                                color="cyan"
                                large
                                @click="guardarVisita(true)"
                            >
                                <v-icon left>
                                    mdi-content-save
                                </v-icon>
                            Guardar
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn large class="ma-2" color="info" @click="generateReport()" v-if="mostrarBotonPlan">
                                Generar Plan
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card class="ml-4 mr-4" :disabled="disabledProyectos" :key="disabledProyectos" max-height="auto" max-width="auto" style="overflow-y: auto; overflow-x: auto">
                    <v-card-title>
                            <span>Compromisos</span>
                    </v-card-title>
                    <v-card-text>
                        <v-col cols="12" v-if="proyecto">
                            <table-component ref="compromisosRef" :headers="headers" :url="url" view="compromiso-visita" :disabled="disabled" :key="keyTable" :showSelect="selectCompromisos" item-key="compromiso_id" :valor="compromisos" labelSearch="Búsqueda de compromiso" :tableHeight=450 :showAdd="true" :padreId="proyecto.id" :disbleItemsPerPage="true" :disablePagination="true" :rowsPerPage=-1></table-component>
                        </v-col>
                    </v-card-text>
                </v-card>
                <br/>
                <v-card v-if="visita_id" class="ml-4 mr-4" :disabled="disabledProyectos" max-height="auto" max-width="auto" style="overflow-y: auto; overflow-x: auto">
                    <v-card-title>
                            <span>Informes</span>
                            <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-col cols="12" v-if="proyecto">
                            <table-component ref="informesRef" :headers="headers_informes" :url="'/documentos/proyecto/' + proyecto.id + '/I/' + visita_id" view="visita-informe" :disabled="disabled" :key="keyTable" :valor="compromisos" labelSearch="Búsqueda de informes" :tableHeight=200 :showAdd="false" :exportExcel="false"></table-component>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
    </v-row>
</template>
<script>
    import axios from "axios";
    import { EventBus } from "@/event/event"
    import { perfilStore } from "@/store/PerfilStore"
    export default {
        data: () => ({
            dialog: false,
            disabled: false,
            valid: true,
            headers: [
                {text: 'Acciones', value: 'actions', width: '160px', filtro:false},
                {text: 'Cod.', value: 'codigo', width: '80px', filtro:false, align: 'center'},
                {text: 'Nombre', value: 'nombre', width: '200px', filtro:false},
                {text: 'Descripción', value: 'descripcion', width: '700px', filtro:false},
                {text: 'Verificador', value: 'verificador.nombre', width: '200px', filtro:false}, 
                {text: 'Descripción del verificador', value: 'descripcion_verificador', width: '300px', filtro:false},
                {text: 'Estado', value: 'estado.nombre', width: '100px', filtro:false},
                {text: 'Observación', value: 'observacion', width: '200px', filtro:false},
                {text: 'Plan de acción', value: 'plan_accion', width: '200px', filtro:false},
                {text: 'Origen', value: 'origen', width: '200px', filtro:false}, 
                {text: 'Norma', value: 'norma', width: '200px', filtro:false}, 
                {text: 'Componente', value: 'componente.nombre', width: '200px', filtro:false}, 
                {text: 'Fase', value: 'fase.nombre', width: '200px', filtro:false}, 
                {text: 'Frecuencia', value: 'frecuencia_sugerida.nombre', width: '200px', filtro:false}, 
                {text: 'Vencimiento', value: 'vencimiento', width: '200px', filtro:false}
            ],
            proyecto: null,
            titular: null,
            contactosSelect: false,
            nombre: '',
            auditoria: false,
            fecha: '',
            keyTable: 0,
            keyContacto: 0,
            contactos: [],
            compromisos: [],
            visita_id: 0,
            contador: 0,
            disabledProyectos: true,
            snackbar: false,
            texto: '',
            estado: 0,
            url: '',
            selectCompromisos: true,
            disabled: true,
            hora_inicio: '',
            tema: '',
            headers_informes: [
                {text: 'Acciones', value: 'actions', width: '200px', filtro:false, sortable: false},
                {text: 'Nombre', value: 'nombre', width: '200px', filtro:false, sortable: true},
                {text: 'Tipo', value: 'flag.nombre', width: '200px', filtro:false, sortable: false},
                {text: 'Fecha creación', value: 'created_at', width: '200px', filtro:false, sortable: false}
            ],
            mostrarBotonPlan: true,
            overlay: false,
            perfilStore: perfilStore(),
        }),
        methods: {
            async open(item, disabled) {
                this.overlay = true
                this.selectCompromisos = !disabled
                this.disabled = disabled
                this.visita_id = 0
                if (item) {
                    await axios.get('visitas/' + item.id ).then((response) => {
                        item = response.data.data
                        this.contactosSelect = true;
                        this.visita_id = item.id
                        this.mostrarBotonPlan = true
                        this.obtenerProyecto(item.proyecto.id)
                        var perfil_id = localStorage.getItem('perfil_id')
                        if (item.estado.id == 17 || this.perfilStore.decryptData(perfil_id) == this.perfilStore.titular) { // 17: Finalizado
                            this.mostrarBotonPlan = false
                        }
                        if (this.$refs.form) {
                            this.proyecto = item.proyecto
                            this.compromisos = item.compromisos
                            this.$refs.sProyecto.setValue(item.proyecto)
                            this.$refs.chAuditoria.setValue(item.auditoria)
                            this.$refs.sContacto.setValue(item.contactos)
                            this.contactos = item.contactos
                            this.$refs.tfNombre.setValue(item.nombre)
                            this.$refs.dpFecha.setValue(item.fecha)
                            this.$refs.tfHora.setValue(item.hora_inicio)
                            this.$refs.tfTema.setValue(item.tema)
                            this.$nextTick(() => {
                                this.$refs.compromisosRef.setValue(item.compromisos)
                            })
                        } else {
                            this.proyecto = item.proyecto
                            this.auditoria = item.auditoria
                            this.contactos = item.contactos
                            this.nombre = item.nombre
                            this.fecha = item.fecha
                            this.hora_inicio = item.hora_inicio
                            this.compromisos = item.compromisos
                            this.tema = item.tema
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    this.mostrarBotonPlan = false
                }
                this.overlay = false
                this.disabled = disabled
                this.dialog = true
            },
            cancelar() {
                if (!this.disabled) {
                    if (this.editado()) {
                        this.$swal.fire({
                            title: "¿Desea guardar los cambios efectuados?",
                            icon: 'warning',
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: "Guardar",
                            denyButtonText: 'No guardar',
                            cancelButtonText: "Cancelar",
                            confirmButtonColor: "#4caf50",
                            allowOutsideClick: false,
                            showCloseButton: true
                        }).then((result) => { 
                            if( result.isConfirmed) {
                                if (this.$refs.compromisosRef.getValue().length == 0) {
                                    this.snackbar = true;
                                    this.texto = 'Debe seleccionar al menos un compromiso'
                                    this.estado = 204
                                    return
                                }
                                this.guardarVisita(true)
                                if (this.$refs.form.validate()) {
                                    this.dialog = false
                                    EventBus.$off('refresh-table')
                                }
                            } else if (result.isDenied) {
                                this.$refs.form.reset();
                                this.$refs.form.resetValidation();
                                this.proyecto = null
                                this.compromisos = []
                                this.url = '';
                                EventBus.$emit('refresh-table')
                                this.dialog = false
                                EventBus.$off('refresh-table')
                                this.refreshEditado()
                            }
                        })
                    } else {
                        this.$refs.form.reset();
                        this.$refs.form.resetValidation();
                        this.proyecto = null
                        this.compromisos = []
                        this.url = '';
                        EventBus.$emit('refresh-table')
                        this.dialog = false
                        EventBus.$off('refresh-table')
                    }
                } else {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();
                    this.proyecto = null
                    this.compromisos = []
                    this.url = '';
                    EventBus.$emit('refresh-table')
                    this.dialog = false
                    EventBus.$off('refresh-table')
                }
            },
            handleSelectedValue(value) {
                if (value != null && value != 'undefined' && value != '') {
                    this.proyecto = value
                    this.obtenerProyecto(this.proyecto.id)
                    this.keyTable++
                }
                
            },
            async obtenerProyecto(id) {
                await axios.get('proyectos/' + id)
                .then((response) => {
                    this.proyecto = response.data.body.items
                    this.titular = response.data.body.items.titular
                    this.disabledProyectos = false
                    this.contactosSelect = true
                    if (this.disabled) {
                        this.url = '/compromisos/visita/' + this.visita_id
                    } else {
                        this.url = '/compromisos/proyecto/' + this.proyecto.id + '/codigo'
                    }
                    ++this.keyTable
                    ++this.keyContacto
                }).catch((error) =>{
                    console.error(error)
                })
            },
            async guardarVisita(mostrarBar) {
                if (this.$refs.form.validate()) {
                    if (this.$refs.compromisosRef.getValue().length == 0) {
                        this.snackbar = true;
                        this.texto = 'Debe seleccionar al menos un compromiso'
                        this.estado = 204
                        return
                    }
                    let contactos = this.$refs.sContacto.getValue();
                    let contactos_id = []
                    contactos.forEach(element => {
                        contactos_id.push(element.contacto_id);
                    });
                    const data = {
                        proyecto_id: this.$refs.sProyecto.getValue().id,
                        contactos: this.$refs.sContacto.getValue(),
                        compromisos: this.$refs.compromisosRef.getValue(),
                        fecha: this.$refs.dpFecha.getValue(),
                        auditoria: this.$refs.chAuditoria.getValue(),
                        nombre: this.$refs.tfNombre.getValue(),
                        hora_inicio: this.$refs.tfHora.getValue(),
                        tema: this.$refs.tfTema.getValue()
                    }
                    if (this.visita_id != 0) {
                        await axios.put('visitas/' + this.visita_id, data)
                        .then((response) => {
                            this.visita_id = response.data.body.items.id
                            this.refresh(response, mostrarBar)
                            this.refreshEditado()
                            this.compromisos = this.$refs.compromisosRef.getValue()
                        }).catch((error) => {
                            console.error(error)
                            this.refresh(error.response, true)
                        })
                    } else {
                        await axios.post('visitas', data)
                        .then((response) => {
                            this.visita_id = response.data.body.items.id
                            this.agendarVisita(this.visita_id)
                            this.refresh(response, mostrarBar)
                            this.mostrarBotonPlan = true
                            this.refreshEditado()
                            this.compromisos = this.$refs.compromisosRef.getValue()
                        }).catch((error) =>{
                            console.error(error)
                            this.refresh(error.response, true)
                        })
                    }
                }
            },
            async generateReport() {
                if (!this.$refs.dpFecha.getValue()) {
                    this.snackbar = true;
                    this.texto = 'Para generar plan debe ingresar Fecha'
                    this.estado = 204
                    return
                }
                if (this.$refs.compromisosRef.getValue().length == 0) {
                    this.snackbar = true;
                    this.texto = 'Debe seleccionar al menos un compromiso'
                    this.estado = 204
                    return
                }
                this.guardarVisita(false)
                let nombre = this.$refs.tfNombre.getValue()
                const params = {
                    visita_id: this.visita_id,
                    nombre: nombre,
                    tipo: 'plan'
                }
                await axios({
                    url: '/pdf-report',
                    method: 'GET',
                    params: params
                }).then(response => {
                    EventBus.$emit('open-informe', 'data:application/pdf;base64,'+response.data.body.base64, response.data.body.documento.id, nombre+'.pdf')
                    // Cambiar estado Visita
                    const data = {
                        nuevo_estado: 15 //Enviado
                    }
                    axios.put('visita/estado/' + this.visita_id, data)
                    .then((response) => {
                    }).catch((error) =>{
                        console.error('Error al descargar el archivo:', error);
                        EventBus.$emit('error-reporte', error.response)
                    })
                }).catch(error => {
                    console.error('Error al descargar el archivo:', error);
                    EventBus.$emit('error-reporte', error.response)
                });
            },
            refresh(response, mostrarBar) {
                if (mostrarBar == true) {
                    this.snackbar = true;
                    this.texto = response.data.message
                    this.estado = response.data.code
                }
            },
            async agendarVisita(visita_id) {
                const data = {
                    titulo: this.$refs.tfNombre.getValue(),
                    descripcion: 'Agendada desde visita a proyecto: ' + this.$refs.sProyecto.getValue().nombre,
                    fecha: this.$refs.dpFecha.getValue(),
                    hora_inicio: this.$refs.tfHora.getValue(),
                    visita_id: visita_id
                };
                await axios.post('agenda', data).then((response) => {
                }).catch((error) => {
                    console.error(error);
                });
            },
            refreshEditado() {
                this.$refs.sProyecto.setEditado(false)
                this.$refs.sContacto ? this.$refs.sContacto.setEditado(false) : null
                this.$refs.dpFecha.setEditado(false)
                this.$refs.tfNombre.setEditado(false)
                this.$refs.tfHora.setEditado(false)
                this.$refs.tfTema.setEditado(false)
                this.$refs.chAuditoria.setEditado(false)
            },
            editado() {

                if (this.$refs.compromisosRef) {
                    if ((JSON.stringify(this.compromisos) !== JSON.stringify(this.$refs.compromisosRef.getValue())) || this.$refs.sContacto.getEditado()) {
                        return true
                    } else {
                        if (this.$refs.sProyecto.getEditado() ||
                            this.$refs.dpFecha.getEditado() ||
                            this.$refs.tfNombre.getEditado() ||
                            this.$refs.tfHora.getEditado() ||
                            this.$refs.tfTema.getEditado() ||
                            this.$refs.chAuditoria.getEditado()) {
                            return true
                        } else {
                            return false
                        }
                    }
                } else {
                    if (this.$refs.sProyecto.getEditado() ||
                        this.$refs.dpFecha.getEditado() ||
                        this.$refs.tfNombre.getEditado() ||
                        this.$refs.tfHora.getEditado() ||
                        this.$refs.tfTema.getEditado() ||
                        this.$refs.chAuditoria.getEditado()) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        },
        mounted() {
            EventBus.$on('close-visita', () => {
                this.dialog = false
            });
        }
    }
</script>

<style scoped>
  .dialog-toolbar {
    position: sticky;
    top: 0;
    background-color: white; /* Cambia esto al color que desees */
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
</style>