<template>
    <v-row justify="end" class="mr-1">
      <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card>
            <v-toolbar flat class="dialog-toolbar">
                <v-toolbar-title class="text-h5">Enviar informe</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <!-- <v-card-title>
                <span class="text-h5">Enviar informe</span>
            </v-card-title> -->
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <text-field-component label="Para *" ref="tfDestinatario" :valor="destinatario" required></text-field-component>
                            </v-col>
                            <v-col cols="12">
                                <text-field-component label="Asunto *" ref="tfAsunto" :valor="asunto" required></text-field-component>
                            </v-col>
                            <v-col cols="12">
                                <file-input-component label="Documento" :valor="documento" ref="documentoRef" disabled></file-input-component>
                            </v-col>
                            <v-col cols="12">
                                <quill-editor v-model="content" :options="editorOptions" class="quill-editor-custom-height"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <br>
            <br>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    large
                    @click="cancelar"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="enviar"
                >
                    <v-icon left>
                        mdi-send
                    </v-icon>
                    Enviar
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
    import axios from "axios";
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import { quillEditor } from 'vue-quill-editor';
    export default {
        components: {
            quillEditor
        },
        data: () => ({  
            dialog: false,
            disabled: false,
            valid: true,
            destinatario: '',
            asunto: '',
            content: '',
            editorOptions: {
                placeholder: '',
                height: '100px'
            },
            id: 0,
            nombrePdf: '',
            documento: new File([], '', { type: 'application/octet-stream' })
        }),
        mounted() {
          
        },
        methods: {
            open(id, archivo) {
                this.dialog = true
                this.id = id
                this.nombrePdf = archivo
                this.documento = this.getDocumento(archivo + '.pdf')
            },
            cancelar() {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
                this.content = ''
                this.dialog = false
            },
            enviar() {
                //this.$refs.load.open();
                const data = {
                    destinatario: this.$refs.tfDestinatario.getValue(),
                    asunto: this.$refs.tfAsunto.getValue(),
                    mail: this.content,
                    pdf: this.nombrePdf
                }
                axios.put('visita/enviar/' + this.id, data)
                .then((response) => {
                    /*  this.snackbar = true;
                    this.texto = response.data.message
                    this.estado = response.data.code */
                    this.$emit('envio-correo', response);
                    this.dialog = false
                    //this.$refs.load.close();
                }).catch((error) => {
                    console.error(error);
                    /* this.snackbar = true;
                    this.texto = error.response.data.message
                    this.estado = error.response.data.code */
                    this.$emit('envio-correo', error.response);
                    this.dialog = false
                    //this.$refs.load.close();
                    //this.$refs.load.close();
                });
            },
            getDocumento(nombre) {
                const file = new File([], nombre, {
                  lastModified: new Date().getTime(),
                  type: 'pdf',
                });
                return file;
            }
        }
    }
</script>
<style scoped>
    .quill-editor-custom-height {
        height: 200px;
    }
    .dialog-toolbar {
        position: sticky;
        top: 0;
        background-color: white; /* Cambia esto al color que desees */
        z-index: 2;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
</style>