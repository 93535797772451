<template>
    <v-row justify="end" class="mr-1">
      <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card>
            <v-toolbar flat class="dialog-toolbar">
                <v-toolbar-title class="text-h5">{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <text-area-component label="Descripción *" ref="tfDescripcion" :valor="descripcion" required :counterValue="255"></text-area-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <small v-if="!disabled">* Indica campo obligatorios</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    large
                    @click="cancelar"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    v-if="!disabled"
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="guardar"
                >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
    import axios from 'axios'
    import { EventBus } from "@/event/event"
    export default {
        props: {
            title: {
                type: String
            }
        },
        data() {
            return {
                disabled: false,
                dialog: false,
                valid: true,
                id: '',
                descripcion: '',
                proyecto_id: 0
            };
        },
        methods: {
            guardar() {
                if (this.$refs.form.validate()) {
                    //this.$refs.load.open();
                    const data = {
                        descripcion: this.$refs.tfDescripcion.getValue(),
                        proyecto_id: this.proyecto_id
                    };
                    axios.post('bitacora', data)
                    .then(response => {
                        this.refreshEditado()
                        this.afterService(response)
                    })
                    .catch(error => {
                        this.afterService(error)
                    });

                }
            },
            cancelar() {
                if (this.editado()) {
                    this.$swal.fire({
                        title: "¿Desea guardar los cambios efectuados?",
                        icon: 'warning',
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Guardar",
                        denyButtonText: 'No guardar',
                        cancelButtonText: "Cancelar",
                        confirmButtonColor: "#4caf50",
                        allowOutsideClick: false,
                        showCloseButton: true
                    }).then((result) => {
                        if( result.isConfirmed) {
                            this.guardar()
                            if (this.$refs.form.validate()) {
                                this.dialog = false
                            }
                        } else if (result.isDenied) {
                            this.$refs.form.reset();
                            this.$refs.form.resetValidation();
                            this.dialog = false;
                            this.refreshEditado()
                        }
                    })
                } else {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();
                    this.dialog = false;
                }
            },
            open(item, disabled) {
                this.proyecto_id = item.proyecto_id
                if (item) {
                    this.id = item.id
                    if (this.$refs.form) {
                        this.$refs.tfDescripcion.setValue(item.descripcion);
                    } else {
                        this.descripcion = item.descripcion;
                    }
                }
                this.disabled = disabled;
                this.dialog = true;
            },
            afterService(response) {
                //this.$refs.load.close();
                EventBus.$emit('refresh-table', response)
            },
            refreshEditado() {
                this.$refs.tfDescripcion.setEditado(false)
            },
            editado() {
                if (this.$refs.tfDescripcion.getEditado()) {
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style scoped>
    .dialog-toolbar {
        position: sticky;
        top: 0;
        background-color: white; /* Cambia esto al color que desees */
        z-index: 2;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
</style>