import { defineStore } from 'pinia';

export const commonStore = defineStore('common', {
  state: () => {
    return {
      estadoVisitaBorrador: 0,
      estadoVisitaEnviada: 1,
      estadoVisitaEjecutada: 2,
      /* flag documentos */
      flagPlanVisita: 1,
      flagActaTerreno: 2,
      flagAdjunto: 3,
      flagDss: 4,
      /* estados compromiso */
      estadoCompromisoCumple: 1,
      estadoCompromisoNoCumple: 3,
      estadoCompromisoObservado: 2,
      overlay: false
    }
  },
  actions: {
    nullOrEmpty(value) {
        return (!value || value == undefined || value == "" || value == null) ? '' : value;
    },
    showOverlay() {
      this.overlay = true
    },
    hideOverlay() {
      this.overlay = false
    }
  }
})