<template>
    <v-row justify="end" class="mr-1">
        <div class="text-center">
            <v-dialog 
                v-model="dialog"
                persistent
            >
                <v-card>
                    <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
                    <v-toolbar flat class="dialog-toolbar">
                        <v-toolbar-title class="text-h5">Búsqueda en documentos</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelar">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                            <v-container>
                                <v-row>
                                    <v-col cols="8">
                                        <text-field-component label="Palabra a buscar" ref="tfTexto" :valor="texto" @enter="handleEnter"></text-field-component>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-btn
                                            style="margin-top: -6px;"
                                            dark
                                            color="success"
                                            @click="busquedaDocumentos"
                                        >
                                            <v-icon left>
                                            mdi-file-search
                                            </v-icon>
                                            Búscar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <table-component :headers="headers" :data="resultados" view="busquedaAvanzada" labelSearch="Búsqueda" :showAdd="false" :key="keyTabla"></table-component>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>

                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-row>
</template>
<script>
    export default {
        data() {
            return {
                dialog: false,
                disabled: false,
                valid: true,
                texto: null,
                headers: [
                    { text: 'Documento', value: 'documento', sortable: true },
                    { text: 'Fecha', value: 'fecha', sortable: true },
                    { text: 'Número', value: 'numero', sortable: true },
                    { text: 'Páginas', value: 'paginas', sortable: true },
                ],
                keyTabla: 0,
                id_expediente: [],
                resultados: [],
                snackbar: false,
                texto: '',
                estado: 0,
            }
        },
        methods: {
            open(id_expediente) {
                this.id_expediente = id_expediente
                this.dialog = true
            },
            cancelar() {
                this.$refs.form.reset()
                this.$refs.form.resetValidation()
                this.resultados = []
                this.dialog = false
            },
            busquedaDocumentos() {
                if (this.$refs.tfTexto.getValue() != null && this.$refs.tfTexto.getValue() != '') {
                    //this.$refs.load.open();
                    this.resultados = [];
                    const urlBusqueda = process.env.VUE_APP_URL_BUSQUEDA + this.id_expediente[0].id_expediente + '&texto=' + this.$refs.tfTexto.getValue()
                    fetch(urlBusqueda).then(response => response.json()).then(data => {
                        const resultado = data.results
                        const results = []
                        resultado.forEach(hit => {
                            let inner_hits_results = hit['inner_hits']['pages']['hits']['hits']
                            inner_hits_results.forEach(inn_hit => {
                                let pp = inn_hit['_source']['page_number']
                                let numero = hit['_source']['numero']
                                let fecha = hit['_source']['fecha']
                                let nombre_proyecto = hit['_source']['nombre_proyecto']
                                let titular = hit['_source']['titular']
                                let region = hit['_source']['nombre_region']
                                let id_expediente = hit['_source']['id_expediente']
                                let namefile = hit['_source']['namefile']
                                let estado = hit['_source']['estado']
                                if (results.indexOf(results[namefile]) > -1) {
                                    results[namefile]['paginas'].push(pp)
                                    results[namefile]['paginas'].sort(function(a, b){return a - b})
                                } else {
                                    results.push(results[namefile] = {
                                        'numero': numero,
                                        'fecha': fecha,
                                        'paginas': [pp],
                                        'documento': namefile,
                                    })
                                }
                            });
                        });
                        results.forEach(element => {
                            this.resultados.push(element) 
                        });
                        this.keyTabla++
                        //this.$refs.load.close();
                    }).catch(error => {
                        console.error('Hubo un problema con la operación fetch:', error);
                    });
                } else {
                    this.snackbar = true;
                    this.texto = 'Debe ingresar una plabra para buscar'
                    this.estado = 204
                }
            },
            handleEnter(value) {
                this.busquedaDocumentos()
            }
        }
    }
</script>
<style scoped>
  .dialog-toolbar {
    position: sticky;
    top: 0;
    background-color: white; /* Cambia esto al color que desees */
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
</style>