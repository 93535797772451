<template>
    <v-row justify="end" class="mr-1">
      <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card>
            <v-toolbar flat class="dialog-toolbar">
                <v-toolbar-title class="text-h5">{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <!-- <v-card-title>
                <span class="text-h5">{{title}}</span>
            </v-card-title> -->
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <text-field-component 
                                    label="Email" 
                                    ref="tfEmail" 
                                    :valor="email" 
                                    required
                                    type="email"
                                    disabled
                                ></text-field-component>
                            </v-col>
                            <v-col cols="12">
                                <text-field-component 
                                    label="Contraseña Email *" 
                                    ref="tfPassword" 
                                    :valor="password" 
                                    required
                                    type="password"
                                ></text-field-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <small v-if="!disabled">* Indica campo obligatorios</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    lass="ma-2"
                    large
                    @click="cancelar"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    v-if="!disabled"
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="actualizar"
                >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
    import axios from 'axios'
    import { perfilStore } from "@/store/PerfilStore"

    export default {
        props: {
            title: {
                type: String
            }
        },
        data() {
            return {
                disabled: false,
                dialog: false,
                valid: true,
                id: '',
                email: '',
                password: '',
                show1: false,
                show2: false,
                perfilStore: perfilStore()
            };
        },
        methods: {
            actualizar() {
                if (this.$refs.form.validate()) {
                    //this.$refs.load.open();
                    const data = {
                        password: this.perfilStore.encryptData(this.$refs.tfPassword.getValue())
                    };
                    axios.post('contrasenaEmail', data)
                    .then(response => {
                        this.afterService(response)
                    })
                    .catch(error => {
                        this.afterService(error)
                    });
                }
            },
            cancelar() {
                if (this.editado()) {
                    this.$swal.fire({
                        title: "¿Desea guardar los cambios efectuados?",
                        icon: 'warning',
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Guardar",
                        denyButtonText: 'No guardar',
                        cancelButtonText: "Cancelar",
                        confirmButtonColor: "#4caf50",
                        allowOutsideClick: false,
                        showCloseButton: true
                    }).then((result) => { 
                        if (result.isConfirmed) {
                            this.actualizar()
                            if (this.$refs.form.validate()) {
                                this.dialog = false
                            }
                        } else if (result.isDenied) {
                            this.$refs.form.reset();
                            this.$refs.form.resetValidation();
                            this.dialog = false;
                            this.refreshEditado()
                        }
                    })
                } else {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();
                    this.dialog = false;
                }
            },
            open(item, disabled) {
                this.disabled = disabled;
                this.dialog = true;
                this.datosUsuario();
            },
            afterService(response) {
                //this.$refs.load.close();
                this.$emit("close-sesion", response)
            },
            async datosUsuario() {
                let user_id = this.perfilStore.decryptData(localStorage.getItem('id'))
                await axios.get('/user/' + user_id)
                .then(response => {
                    if (this.$refs.form) {
                        this.$refs.tfEmail.setValue(response.data.body.items.email)
                    } else {
                        this.email = response.data.body.items.email
                    }
                    this.refreshEditado()
                })
                .catch(error => {
                    this.afterService(error)
                });
            },
            refreshEditado() {
                this.$refs.tfPassword.setEditado(false)
                this.$refs.tfEmail.setEditado(false)
            },
            editado() {
                if (this.$refs.tfPassword.getEditado() ||
                    this.$refs.tfEmail.getEditado()) {
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style scoped>
    .dialog-toolbar {
        position: sticky;
        top: 0;
        background-color: white; /* Cambia esto al color que desees */
        z-index: 2;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
</style>