<template>
    <v-row justify="end" class="mr-1">
        <div class="text-center">
      <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card>
            <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
            <v-toolbar flat class="dialog-toolbar">
                <v-toolbar-title class="text-h5">Titular</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled" class="mb-3">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <text-field-component label="Nombre *" ref="tfNombre" :valor="nombre" required></text-field-component>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <text-field-component label="Rut *" ref="tfRut" hint="12345678-9" :valor="rut" type="rut" required></text-field-component>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <text-field-component label="Dirección *" ref="tfDireccion" :valor="direccion" required></text-field-component>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <text-field-component label="Email" ref="tfEmail" type="email" :valor="email"></text-field-component>
                            </v-col>
                            <v-col cols="6" class="mt-4">
                                <select-component label="Seleccione una region *" ref="sRegion" url="/regiones" :valor="region" @selected-region="handleSelectedRegion" type="regiones" required></select-component>
                            </v-col>
                            <v-col cols="6" class="mt-4">
                                <select-component label="Seleccione una Comuna *" ref="sComuna" :url="'comunas/region/' + region_id" :valor="comuna" :key="keyComuna" required></select-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <small v-if="!disabled">* Indica campo obligatorios</small>
            </v-card-text>
            <v-card class="ml-4 mr-4" :disabled="disabledContactos" :key="disabledContactos">
                <v-card-title>
                    <span>Contactos</span>
                </v-card-title>
                <v-card-text>
                    <table-component class="ml-4" :headers="headers" :url="'/contactos/titular/' + titular_id" view="contacto" :disabled="disabled" labelSearch="Búsqueda de contactos" :key="keyTabla" :padreId="titular_id"></table-component>
                </v-card-text>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    large
                    @click="cancelar"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    v-if="!disabled"
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="guardar"
                >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    </v-row>
</template>

<script>
    import axios from "axios";
    import { EventBus } from "@/event/event"

    export default {
        data: () => ({  
            dialog: false,
            valid: true,
            nombre: '',
            rut: '',
            email: '',
            direccion: '',
            disabled: false,
            headers: [
                { text: 'Nombre', value: 'nombre', filtro:false },
                { text: 'Email', value: 'email', filtro:false },
                { text: 'Teléfono', value: 'telefono', filtro:false }, 
                { text: 'Creado por', value: 'createdby.nombre', filtro:false },
                { text: 'Acciones', value: 'actions', sortable: false, align: 'center', filtro:false }
            ],
            titular_id: 0,
            keyTabla: 0,
            contador: 0,
            disabledContactos: true,
            region: null,
            comuna: null,
            region_id: 0,
            keyComuna: 0,
            snackbar: false,
            texto: '',
            estado: 0
        }),
        methods: {
            async guardar() {
                if (this.$refs.form.validate()) {
                    //this.$refs.load.open();
                    const data = {
                        nombre: this.$refs.tfNombre.getValue(),
                        correo: this.$refs.tfEmail.getValue(),
                        rut: this.$refs.tfRut.getValue(),
                        direccion: this.$refs.tfDireccion.getValue(),
                        region_id: this.$refs.sRegion.getValue().id,
                        comuna_id: this.$refs.sComuna.getValue().id
                    }
                    if (this.titular_id == 0) {
                        await axios.post('titulares', data)
                        .then(response => {
                            this.titular_id = response.data.body.items.id
                            this.disabledContactos = false
                            this.afterService(response)
                            this.refreshEditado()
                        })
                        .catch( error => {
                            console.error(error)
                            /* this.afterService(error.response) */
                            this.snackbar = true;
                            this.texto = error.response.data.message
                            this.estado = error.response.data.code
                            //this.$refs.load.close();
                        })
                    } else {
                        await axios.put('titulares/' + this.titular_id, data)
                        .then(response => {
                            this.afterService(response)
                            this.refreshEditado()
                        })
                        .catch( error => {
                            console.error(error)
                            /* this.afterService(error.response) */
                            this.snackbar = true;
                            this.texto = error.response.data.message
                            this.estado = error.response.data.code
                            //this.$refs.load.close();
                        })
                    }
                }
            },
            open(item, disabled) {
                if (item) {
                    this.titular_id = item.id
                    ++this.keyTabla
                    this.disabledContactos = disabled
                    if (this.$refs.form) {
                        this.$refs.tfNombre.setValue(item.nombre)
                        this.$refs.tfRut.setValue(item.rut)
                        this.$refs.tfEmail.setValue(item.correo)
                        this.$refs.tfDireccion.setValue(item.direccion)
                        this.$refs.sRegion.setValue(item.region);
                        this.$refs.sComuna.setValue(item.comuna);
                    } else {
                        this.nombre = item.nombre
                        this.rut = item.rut
                        this.email = item.correo
                        this.direccion = item.direccion
                        this.region = item.region
                        this.comuna = item.comuna
                    }
                } else {
                    this.titular_id = 0;
                }
                this.disabled = disabled
                this.dialog = true
            },
            afterService(response) {
                EventBus.$emit('reload-titular', response)
                this.$emit("reload-titular-form", ++this.contador, response)
                //this.$refs.load.close();
                //this.cancelar()
            },
            reloadContactos() {
                ++this.keyTabla
            },
            cancelar() {
                if (this.editado()) {
                    this.$swal.fire({
                        title: "¿Desea guardar los cambios efectuados?",
                        icon: 'warning',
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Guardar",
                        denyButtonText: 'No guardar',
                        cancelButtonText: "Cancelar",
                        confirmButtonColor: "#4caf50",
                        allowOutsideClick: false,
                        showCloseButton: true
                    }).then((result) => { 
                        if( result.isConfirmed) {
                            this.guardar()
                            if (this.$refs.form.validate()) {
                                this.dialog = false
                            }
                        } else if (result.isDenied) {
                            this.$refs.form.reset();
                            this.$refs.form.resetValidation();
                            this.dialog = false
                            this.refreshEditado()
                        }
                    })
                } else {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();
                    this.dialog = false
                }
            },
            handleSelectedRegion(value){
                if(value) {
                    this.region_id = value.id
                    this.keyComuna++
                }
            },
            refreshEditado() {
                this.$refs.tfNombre.setEditado(false)
                this.$refs.tfEmail.setEditado(false)
                this.$refs.tfRut.setEditado(false)
                this.$refs.tfDireccion.setEditado(false)
                this.$refs.sRegion.setEditado(false)
                this.$refs.sComuna.setEditado(false)
            },
            editado() {
                if (this.$refs.tfNombre.getEditado() ||
                    this.$refs.tfEmail.getEditado() ||
                    this.$refs.tfRut.getEditado() ||
                    this.$refs.tfDireccion.getEditado() ||
                    this.$refs.sRegion.getEditado() ||
                    this.$refs.sComuna.getEditado()) {
                    return true
                } else {
                    return false
                }
            }
        },
        mounted() {
            EventBus.$on('reload-contactos', () => {
                ++this.keyTabla;
            });
        },
        
    }
</script>

<style scoped>
.margen-div {
    margin-top: -20px;
  }
  .dialog-toolbar {
    position: sticky;
    top: 0;
    background-color: white; /* Cambia esto al color que desees */
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
</style>